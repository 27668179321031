<template>
    <div class="tools">
        <BackOnTop />
        <h2>毛孩小工具</h2>
        <div class="item_list">
            <!-- <img :src="require('../../assets/images/img_05.png')" alt="" /> -->
            <div
                v-for="(el, idx) in funcList"
                :key="idx"
                class="d_flex"
                @click="redirect(el.href)"
            >
                <h6>{{ el.name }}</h6>
                <!-- <img :src="require(`${el.imgSrc}`)" alt="" /> -->
                <img src="../../assets/images/img_05.png" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';

export default {
    name: 'ToolIndex',
    components: { BackOnTop },
    data: () => ({
        funcList: [
            {
                name: '營養計算神器',
                href: '/tools/calculator',
                imgSrc: '../../assets/images/img_05.png',
            },
        ],
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    methods: {
        redirect(link) {
            setTimeout(() => {
                this.$router.push(link);
            }, 500);
        },
    },
};
</script>
<style lang="scss" scoped>
.tools {
    h2 {
        color: $color_main_first;
        font-size: 26px;
        font-weight: bold;
        padding: 12px 16px;
        line-height: 1;
        text-align: left;
    }
    .item_list {
        > div {
            border-radius: 10px;
            height: 80px;
            width: calc(100% - 32px);
            background-color: #f1dacc;
            margin: 16px auto;
            justify-content: space-between;
            align-items: center;
            transition: background 0.3s;
            position: relative;
            overflow: hidden;

            h6 {
                font-size: 20px;
                padding-left: 20px;
                font-weight: bold;
                color: $color_main_first;
            }
            img {
                height: 100%;
                width: auto;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                height: 100vw;
                width: 100vw;
                background-color: #FF9F48;
                border-radius: 50%;
                opacity: 0;
                pointer-events: none;
                transition: all ease-in-out 0.6s;
                transform: scale(1) translate(-50%, -50%);
                transform-origin: left top;
            }
            &:active {
                &::after {
                    padding: 0;
                    margin: 0;
                    opacity: 0.1;
                    transition: 0s;
                    transform: scale(0, 0) translate(-50%, -50%);
                }
            }
        }
    }
}
</style>
